import Style from "./style";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Button, ChakraProvider, Container, Flex, Image } from "@chakra-ui/react";
import theme from "../../styles/theme";
import PageTitle from "../../components/PageTitle/PageTitle";

const logo = require("../../assets/Login.png");

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <ChakraProvider>
      <PageTitle title="Página não encontrada" />
      <Style>
        <Flex gap="10">
          <Image src={logo} w={300} />
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={"center"}
            fontSize="xl"
            gap="4"
          >
            Essa página não foi encontrada.
            <Button
              color="white"
              bgColor={theme.colors.blue}
              _hover={{
                color: "white",
                bgColor: theme.colors.blue800,
              }}
              _active={{
                color: theme.colors.blue,
                bgColor: theme.colors.white_secondary,
              }}
              onClick={() => navigate("/")}
            >
              Voltar para a página inicial
            </Button>
          </Container>
        </Flex>
      </Style>
    </ChakraProvider>
  );
}
