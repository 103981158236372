import { FaLock, FaUserAlt } from "react-icons/fa";
import { useContext, useState } from "react";
import { Container, Main } from "./style";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Flex, Image } from "@chakra-ui/react";

const login = require("../../assets/Login.png");

export default function Login() {
  const { LogIn, IsLogged, IsAdmin } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleSignIn() {
    LogIn(email, password);
  }

  if (IsLogged()) {
    if (IsAdmin()) return <Navigate to="/dashboard" />;
    else return <Navigate to="/" />;
  }

  return (
    <Container>
      <PageTitle title="Entrar" />
      <Main>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-logo">
            <FaUserAlt />
          </div>
          <p>LOGIN</p>
          <div className="input-data">
            <label htmlFor="login">EMAIL:</label>
            <input
              type="text"
              id="login"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <FaUserAlt style={{ fontSize: 20 }} />
          </div>
          <div className="input-data">
            <label htmlFor="password">SENHA:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <FaLock style={{ fontSize: 20 }} />
          </div>
          <Flex justify="center" align="center" wrap="wrap" gap="6">
            <button onClick={handleSignIn}>LOGIN</button>
          </Flex>
        </form>
        <Image
          mb="90"
          objectFit="cover"
          src={login}
          alt="Imagem escrita 'Winner Presentes Corporativos'"
        />
      </Main>
      <ToastContainer />
    </Container>
  );
}
