import { AspectRatio, Box, Button, Center, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { CatalogContext } from "../../contexts/CatalogContext";
import { IProduct } from "../../interfaces/IProduct";
import { convertId } from "../../services/id";

const fallback = require("../../assets/placeholder.png");

export default function ProductPage() {
  const { products } = useContext(CatalogContext);
  const { productId } = useParams();
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const filterData = products.find((product) => product.id === Number(productId));
    setProduct(filterData!);
  }, [productId, products]);

  return (
    <Center>
      <PageTitle title={`${product.name}`} />
      <SimpleGrid columns={[1, 1, 2]}>
        <Center flexDirection="column" width="100%" maxHeight="380px">
          <AspectRatio mb="4" width={["100%", "350px"]} height="100%" ratio={1}>
            <Image
              src={
                product.images?.length > 0
                  ? product.images.sort((a, b) => a.id - b.id)[currentImage].url
                  : fallback
              }
              alt="selectedImage"
              objectFit="cover"
            />
          </AspectRatio>
          <Flex
            width="100%"
            gap="2"
            maxH="50rem"
            css={{
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bbccff",
                borderRadius: "24px",
              },
            }}
          >
            {product.images?.length > 0 ? (
              product.images
                .sort((a, b) => a.id - b.id)
                .map((image, index) => {
                  return (
                    <AspectRatio key={index} ratio={1} width="58px">
                      <Image
                        objectFit="cover"
                        cursor="pointer"
                        src={image.url}
                        border="solid 1px transparent"
                        _hover={{ boxShadow: "md", border: "solid 1px #00000030" }}
                        onMouseEnter={() => setCurrentImage(index)}
                        onClick={() => setCurrentImage(index)}
                      />
                    </AspectRatio>
                  );
                })
            ) : (
              <AspectRatio ratio={1} width="60px">
                <Image
                  objectFit="cover"
                  cursor="pointer"
                  src={fallback}
                  border="solid 1px transparent"
                  _hover={{ boxShadow: "md", border: "solid 1px #00000030" }}
                />
              </AspectRatio>
            )}
          </Flex>
        </Center>
        <Flex
          mt={["10", "10", "2"]}
          direction="column"
          ml={["0", "0", "6"]}
          fontSize={["15", "16", "17", "18"]}
        >
          <Flex justify="space-between">
            <Text fontWeight="bold" fontSize="21px">
              {convertId(product.id)}
            </Text>
            {product.category !== undefined && product.category !== null && (
              <Text
                cursor="default"
                pb="2"
                pt="2"
                pl="4"
                pr="4"
                bgColor="telegram.600"
                color="white"
                borderRadius="16"
                fontWeight="bold"
                fontSize={["12", "13", "14"]}
              >
                {product.category.name}
              </Text>
            )}
          </Flex>
          <Text fontWeight="light" fontSize="30px">
            {product.name}
          </Text>
          <Box color="#667">
            <Text mt="8">
              <b>Descrição:</b> {product.description}
            </Text>
            {product.width !== undefined && product.width !== null && (
              <Text mt="4">
                <b>Largura:</b> {product.width} cm
              </Text>
            )}
            {product.height !== undefined && product.height !== null && (
              <Text mt="4">
                <b>Altura:</b> {product.height} cm
              </Text>
            )}
            {product.thickness !== undefined && product.thickness !== null && (
              <Text mt="4">
                <b>Circunferência:</b> {product.thickness} cm
              </Text>
            )}
            {product.weight !== undefined && product.weight !== null && (
              <Text mt="4">
                <b>Peso aproximado:</b> {product.weight} cm
              </Text>
            )}
            <Text mt="4">
              <b>Cores:</b>
            </Text>
            <Flex gap="1">
              {product.images?.length > 0 &&
                product.images
                  .sort((a, b) => a.id - b.id)
                  .map((image, index) => {
                    return (
                      <>
                        {image.color !== undefined && image.color !== null && (
                          <Button
                            key={index}
                            size="md"
                            bgColor={image.color!}
                            borderColor="blackAlpha.300"
                            borderWidth="1px"
                            shadow="md"
                            opacity="0.8"
                            _hover={{ opacity: 1 }}
                            onClick={() => setCurrentImage(index)}
                            onMouseEnter={() => setCurrentImage(index)}
                          />
                        )}
                      </>
                    );
                  })}
            </Flex>
          </Box>
        </Flex>
      </SimpleGrid>
    </Center>
  );
}
