import { Center, Heading, Spinner, VStack } from "@chakra-ui/react";

interface LoadingPageProps {
  message?: string;
}

export function LoadingPage({ message }: LoadingPageProps) {
  return (
    <Center>
      <VStack alignItems="center" justifyContent="center" gap="4">
        {message && <Heading>{message}</Heading>}
        <Spinner size="lg" />
      </VStack>
    </Center>
  );
}
