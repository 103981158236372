import axios, { AxiosError, AxiosResponse } from "axios";
import apiConfig from "../config/apiConfig";

const apiConfigurations = apiConfig();

const api = axios.create({
  baseURL: apiConfigurations.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});

interface HttpError {
  success: false;
  errors: Array<{
    code: String;
    message: String;
  }>;
}

export type RequestError = AxiosError<HttpError>;

api.interceptors.response.use(
  (res: AxiosResponse<any>) => res,
  async (res) => {
    if (res.status === "200") return res;
    if (res instanceof AxiosError) {
      removeToken();
      return Promise.reject(res?.response?.data);
    }
  }
);

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

export default api;
