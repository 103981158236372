import { IUser } from "../../interfaces/IUser";
import api from "../api";

const address = "/users";

export async function findUser(id: number): Promise<IUser> {
  const response = await api.get(`${address}/${id}`);
  return response.data;
}

export async function listUsers(): Promise<IUser[]> {
  const response = await api.get(address);
  return response.data;
}

export async function createUser(data): Promise<IUser> {
  const response = await api.post(address, data);
  return response.data;
}

export async function updateUser(id: number, data): Promise<IUser> {
  const response = await api.patch(`${address}/update-profile/${id}`, data);
  return response.data;
}
