import { IProduct } from "../../interfaces/IProduct";
import api from "../api";

const address = "/products";

export async function findProduct(id: number): Promise<IProduct> {
  const response = await api.get(`${address}/${id}`);
  return response.data;
}

export async function listProducts(): Promise<IProduct[]> {
  const response = await api.get(address);
  return response.data;
}

interface CreateProductData {
  name: string;
  description: string;
  weight?: number;
  height?: number;
  width?: number;
  thickness?: number;
  companyId?: number | null;
  categoryId?: number | null;
}

export async function createProduct(data): Promise<IProduct> {
  const product: CreateProductData = {
    name: data.name,
    description: data.description,
  };

  if (data.height) product.height = data.height;
  if (data.width) product.width = data.width;
  if (data.weight) product.weight = data.weight;
  if (data.thickness) product.thickness = data.thickness;
  if (data.companyId) product.companyId = data.companyId;
  if (data.categoryId) product.categoryId = data.categoryId;

  const response = await api.post(address, product);
  return response.data;
}

export async function updateProduct(id: number, data): Promise<IProduct> {
  const product: CreateProductData = {
    name: data.name,
    description: data.description,
  };

  if (data.height) product.height = data.height;
  if (data.width) product.width = data.width;
  if (data.weight) product.weight = data.weight;
  if (data.thickness) product.thickness = data.thickness;
  if (data.companyId !== "0" && data.companyId > 0 && data.companyId !== undefined)
    product.companyId = Number(data.companyId);
  else {
    product.companyId = null;
  }
  if (data.categoryId !== "0" && data.categoryId > 0 && data.categoryId !== undefined)
    product.categoryId = Number(data.categoryId);
  else {
    product.categoryId = null;
  }

  const response = await api.put(`${address}/${id}`, product);
  return response.data;
}

export async function deleteProduct(id: number) {
  const response = await api.delete(`${address}/${id}`);
  return response.data;
}

export async function uploadImage(productId: number, data) {
  const response = await api.post(
    `${address}/${productId}/upload`,
    {
      image: data,
    },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response.data;
}

export async function deleteImage(id: number) {
  const response = await api.delete(`${address}/images/${id}`);
  return response.data;
}
