import { Center, Heading, Text } from "@chakra-ui/react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function About() {
  return (
    <>
      <PageTitle title="Sobre Nós" />
      <Center flexDirection="column">
        <Heading mb="10">QUEM SOMOS</Heading>
        <Text textAlign="justify" width="85%" lineHeight={"140%"}>
          Atuando a mais de 3 anos na área de brindes e presentes corporativos, a Winner é uma
          empresa que traz uma linha de produtos inovadores e serviços diferenciados. <br />
          <br />
          Localizada na cidade de São Paulo, atende empresas em diversas áreas de atuação de todo o
          Brasil, com a elaboração de projetos especiais e soluções em produtos promocionais,
          campanhas de incentivo, e presentes corporativos trazendo uma grande variedade de produtos
          e soluções para seus clientes. <br />
          <br />
          Fabricante de folheados e peças em metal em geral (pingentes, brincos, pins, chaveiros,
          pulseiras, etc), e também na área de joalheria e pedras preciosas, com excelente qualidade
          dos produtos e custo-benefício. <br />
          <br />
          Desenvolve sua própria linha de produtos e ainda desenvolve projetos especiais para
          empresas conforme sua necessidade, adaptado para as condições da campanha solicitada.
        </Text>
        <Heading m="10">MISSÃO</Heading>
        <Text textAlign="justify" width="85%" lineHeight={"140%"}>
          Oferecer ao mercado produtos nacionais e importados de qualidade, para suprir o segmento
          de brindes, adornos e itens destinados a utilização doméstica, visando baixo custo ao
          cliente.
        </Text>
      </Center>
    </>
  );
}
