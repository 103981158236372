import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { ICompany } from "../../../interfaces/ICompany";
import { useGetCompanies } from "../../../services/hooks/useCompanies";
import { useCreateUser } from "../../../services/hooks/useUsers";
import { ErrorPage } from "../../components/Error";
import { FieldInput } from "../../components/Form/FieldInput";
import { LoadingPage } from "../../components/Loading";

export function CreateUser() {
  const navigate = useNavigate();
  const toast = useToast();
  const { data, isFetching, isError } = useGetCompanies();

  function onSuccess() {
    toast({ title: "Usuário criado com sucesso", status: "success", position: "top-right" });
    goBack();
  }
  function onError() {
    toast({ title: "Preencha todas as informações", status: "error", position: "top-right" });
  }

  if (isFetching) return <LoadingPage message="Buscando opções do usuário" />;
  if (isError || data === undefined) return <ErrorPage />;

  const goBack = () => navigate("/dashboard/users");

  return <CreateUserForm companies={data} onSuccess={onSuccess} onError={onError} />;
}

interface CreateUserFormProps {
  companies: ICompany[];
  onSuccess: () => void;
  onError: () => void;
}

function CreateUserForm({ companies, onSuccess }: CreateUserFormProps) {
  const { mutate } = useCreateUser();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      companyId: 0,
    },
    onSubmit: (values, helpers) => {
      helpers.resetForm();
      mutate(values, { onSuccess });
    },
  });

  return (
    <Flex direction="column">
      <PageTitle title="Criar Usuário - Dashboard" />
      <Heading fontSize="21" textAlign="center" mb="6">
        ADICIONAR USUÁRIO
      </Heading>
      <FormikProvider value={formik}>
        <Form>
          <FieldInput label={"Nome"} name={"name"} type="text" />
          <FieldInput label={"E-mail"} name={"email"} type="text" />
          <FieldInput label={"Senha"} name={"password"} type="text" />
          <FormControl mt="1rem">
            <Flex align="end" justify="center">
              <FormLabel
                minW="11rem"
                ml="-5rem"
                textAlign={"right"}
                htmlFor="company"
                fontSize="1rem"
              >
                Companhia:
              </FormLabel>
              <Field
                as={Select}
                colorScheme={"telegram"}
                border="none"
                h="9"
                bgColor="facebook.900"
                color="white"
                name="companyId"
              >
                <option style={{ color: "white", backgroundColor: "#0d1b33" }} value={0}>
                  Nenhuma
                </option>
                {companies.map((company) => {
                  return (
                    <option
                      style={{ color: "white", backgroundColor: "#0d1b33" }}
                      key={company.id}
                      value={company.id}
                    >
                      {company.name}
                    </option>
                  );
                })}
              </Field>
            </Flex>
          </FormControl>
        </Form>
        <Center mt="6" gap="2">
          <Button
            as={Link}
            type="button"
            variant="ghost"
            _hover={{ color: "black", bgColor: "white" }}
            to="/dashboard/users"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme={"telegram"}
            bgColor={"telegram.700"}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Criar
          </Button>
        </Center>
      </FormikProvider>
    </Flex>
  );
}
