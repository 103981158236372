import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AnnouncementSliderStyle from "./style";

const bannerPais = require("../../assets/bannerPais.jpg");
const bannerPontaEstoque = require("../../assets/bannerPontaEstoque.jpg");

// SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay]);

export default function AnnouncementSlider(props) {
  const images = [bannerPais, bannerPontaEstoque];

  return (
    <AnnouncementSliderStyle>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {}}
        loop
        autoplay
      ></Swiper>
    </AnnouncementSliderStyle>
  );
}
