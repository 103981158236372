import { Button, Flex, IconButton } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useGetUsers } from "../../../services/hooks/useUsers";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";
import { CustomTable } from "../../components/Table";

export function Users() {
  const { data, isError, isFetching } = useGetUsers();

  if (isFetching) return <LoadingPage message="Buscando usuários" />;
  if (isError) return <ErrorPage />;

  return (
    <Flex direction="column">
      <PageTitle title="Usuários - Dashboard" />
      <Flex>
        <Button
          as={Link}
          colorScheme={"telegram"}
          bgColor="telegram.700"
          color="white"
          to={"/dashboard/users/new"}
        >
          Adicionar Usuário
        </Button>
      </Flex>
      <CustomTable
        columns={[
          { header: "ID", propKey: (obj) => obj.id },
          { header: "Nome", propKey: (obj) => obj.name },
          { header: "E-mail", propKey: (obj) => obj.email },
          { header: "Perfil", propKey: (obj) => obj.profile || "-" },
          { header: "Companhia", propKey: (obj) => obj.company?.name || "-" },
          {
            header: "Ações",
            customComponent: function Actions(obj) {
              return (
                <IconButton
                  as={Link}
                  aria-label={"Editar"}
                  icon={<FaEdit />}
                  colorScheme={"telegram"}
                  bgColor="telegram.700"
                  to={`/dashboard/users/${obj.id}`}
                />
              );
            },
          },
        ]}
        data={data?.sort((a, b) => a.id - b.id) ?? []}
        getDataId={(obj) => obj.id}
        title="Lista de Usuários"
      ></CustomTable>
    </Flex>
  );
}
