import styled from "styled-components";

export default styled.div`
  div.swiper-button-prev,
  div.swiper-button-next {
    color: #fff;
  }
  span.swiper-pagination-bullet {
    background: #999999;
    opacity: 1;
  }
  span.swiper-pagination-bullet-active {
    background: #fff;
  }
  div.swiper-slide,
  div.swiper-slide-active {
    position: relative;
  }

  div.swiper-slide {
    max-height: 580px;
  }
`;
