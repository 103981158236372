import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { ICategory } from "../../interfaces/ICategory";
import { RequestError } from "../api";
import {
  createCategory,
  deleteCategory,
  findCategory,
  listCategories,
  updateCategory,
} from "../routes/categories";

export function useGetCategories() {
  return useQuery<ICategory[], RequestError>("listCategory", () => listCategories(), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useGetCategory(id: number) {
  return useQuery<ICategory, RequestError>(
    ["findCategory", Number(id)],
    () => findCategory(Number(id)),
    {
      onError: () => toast.error("Erro no servidor"),
    }
  );
}

export function useCreateCategory() {
  return useMutation(createCategory);
}

export function useUpdateCategory(id: number) {
  return useMutation((data: any) => updateCategory(id, data));
}

export function useDeleteCategory() {
  return useMutation((id: number) => deleteCategory(id));
}
