import { Center, SimpleGrid } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import Product from "../../components/Product/Product";
import { CatalogContext } from "../../contexts/CatalogContext";
import { IProduct } from "../../interfaces/IProduct";

export default function Features() {
  const { products: data } = useContext(CatalogContext);
  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);

  useEffect(() => {
    function filterData() {
      return data.sort((a, b) => b.id - a.id).slice(0, 30);
    }
    setProducts(filterData);
  }, [data]);

  return (
    <>
      <PageTitle title="Lançamentos" />
      <Center>
        <SimpleGrid columns={[2, 3, 4, 5]} gap="4" maxW="100%">
          {products.map((product) => {
            return <Product key={product.id} product={product} />;
          })}
        </SimpleGrid>
      </Center>
    </>
  );
}
