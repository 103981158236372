import {createContext, useContext, useState} from "react";
import {IProduct} from "../interfaces/IProduct";
import {ICategory} from "../interfaces/ICategory";
import {useGetProducts} from "../services/hooks/useProducts";
import {useGetCategories} from "../services/hooks/useCategories";
import {AuthContext} from "./AuthContext";
import {Flex, Heading, Spinner} from "@chakra-ui/react";
import theme from "../styles/theme";

interface Props {
  children?: React.ReactNode;
}

interface CatalogContextData {
  products: IProduct[];
  categories: ICategory[];
  hasCompanyPage(): boolean;
  companyName: string;
}

export const CatalogContext = createContext<CatalogContextData>({} as CatalogContextData);

export const CatalogProvider: React.FC<Props> = ({ children }) => {
  const { userData } = useContext(AuthContext);
  const { data: products, isLoading: isLoadingProducts } = useGetProducts();
  const { data: categories, isLoading: isLoadingCategories } = useGetCategories();
  const [companyName, setCompanyName] = useState("");

  if (
    products === undefined ||
    categories === undefined ||
    isLoadingProducts ||
    isLoadingCategories
  )
    return (
      <Flex
        gap="5"
        width="100vw"
        height="100vh"
        bgColor={theme.colors.blue}
        wrap="wrap"
        justify="center"
        align="center"
      >
        <Spinner color="white" width="80px" height="80px" mb="4" />
        <Heading color="white">Carregando produtos</Heading>
      </Flex>
    );

  const hasCompanyPage = () => {
    if (userData.companyId === null) return false;

    return products.some((product) => {
      if (product.companyId === userData.companyId && product.company?.name !== undefined) {
        setCompanyName(product.company.name);
        return true;
      }
    });
  };

  return (
    <CatalogContext.Provider value={{ products, categories, hasCompanyPage, companyName }}>
      {children}
    </CatalogContext.Provider>
  );
};
