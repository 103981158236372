import {
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Th,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface TableKeyColumns<O, K extends keyof O> {
  propKey: K | ((o: O) => Value);
  default?: Value;
  header: string;
  customComponent?: (o: O) => React.ReactNode;
}

type Value = string | number | boolean;

interface TableCustomColumns<O, K extends keyof O> {
  propKey?: K | ((o: O) => Value);
  customComponent: (o: O) => React.ReactNode;
  default?: Value;
  header: string;
}

export type TableColumns<O> = (TableKeyColumns<O, keyof O> | TableCustomColumns<O, keyof O>)[];
export interface TableProps<O> {
  title: string;
  data: O[];
  getDataId: (data: O) => string | number;
  columns: TableColumns<O>;
}

export function CustomTable<O>({ columns, title, data, getDataId }: TableProps<O>) {
  return (
    <TableContainer overflow="hidden">
      <ChakraTable variant="unstyled" overflow="hidden">
        <TableCaption color="white" placement="top" fontSize="xl">
          {title}
        </TableCaption>
        <Thead>
          <Tr>
            {columns.map(({ header }) => (
              <Th color={"#bcd"} key={header}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr key={getDataId(item)}>
              {columns.map(({ header, propKey, customComponent }) => (
                <Td key={header + getDataId(item)}>
                  {(customComponent
                    ? customComponent(item)
                    : typeof propKey === "function"
                    ? propKey(item)
                    : "-") ?? "-"}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
}
