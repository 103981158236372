import { Flex, FormControl, FormErrorMessage, FormLabel, Textarea } from "@chakra-ui/react";
import { useField } from "formik";

interface FieldInputProps {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  testID?: string;
}

export const FieldTextarea = ({
  label,
  name,
  isDisabled,
  isRequired,
  placeholder,
  testID,
  ...props
}: FieldInputProps) => {
  const [field, meta] = useField(name);

  const hasAnErrorAndHasBeenTouched = !!meta.error && meta.touched;

  return (
    <FormControl
      mt="1rem"
      isInvalid={hasAnErrorAndHasBeenTouched}
      isRequired={isRequired}
      isDisabled={isDisabled}
      placeholder={placeholder}
    >
      <Flex>
        <FormLabel textAlign="right" minW="11rem" ml="-5rem" htmlFor={name} fontSize="1rem">
          {label}:
        </FormLabel>
        <Textarea
          {...props}
          {...field}
          id={name}
          border="none"
          color="white"
          bgColor="facebook.900"
          data-testid={testID}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </Flex>
    </FormControl>
  );
};
