import styled from "styled-components";

export default styled.div`
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(#6de2fd, #c5f3fd);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: start;
  justify-content: space-evenly;

  form {
    background: #4285f499;
    border-radius: 1rem;
    box-shadow: 0 4px 5px -2px gray;

    height: 300px;
    width: 100%;
    max-width: 350px;
    padding: 2rem 1rem;
    text-align: center;

    color: white;

    .form-logo {
      width: 100px;
      height: 100px;
      margin: -50px auto;

      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50px);

      background-color: #4285f4;
      border-radius: 100%;

      font-size: 60px;
    }

    p {
      color: white;
    }

    .input-data {
      text-align: left;
      margin: 2rem 1.5rem;

      label {
        display: block;
        font-weight: 600;
        font-size: 0.9rem;
      }

      input {
        width: 85%;
        background-color: transparent;
        margin-top: 1rem;
        margin-right: 0.5rem;

        border: 0;
        border-bottom: 1px solid white;

        color: white;
        font-size: 1.125rem;
        transition: all 0.2s;

        &:focus {
          outline: none;

          background-color: #3f81ed99;
          border-radius: 5px;

          font-size: 1.2rem;
          line-height: 1.5rem;
        }
      }
    }

    button {
      width: 100%;
      max-width: 120px;
      height: 50px;
      margin-top: 8px;

      color: white;
      font-size: 1.2rem;
      font-weight: bold;

      background-color: #34a853;
      border: 0;
      border-radius: 60px;
      transition: filter 0.2s;
      cursor: pointer;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  img {
    width: 400px;
  }

  p {
    color: #5c9c35;
    font-weight: bold;
    font-size: 1.5rem;
  }

  @media (max-width: 576px) {
  }
`;
