import { ICompany } from "../../interfaces/ICompany";
import api from "../api";

const address = "/companies";

export async function findCompany(id: number): Promise<ICompany> {
  const response = await api.get(`${address}/${id}`);
  return response.data;
}

export async function listCompanies(): Promise<ICompany[]> {
  const response = await api.get(address);
  return response.data;
}

export async function createCompany(data): Promise<ICompany> {
  return api.post(address, data).then((result) => result.data);
}

export async function updateCompany(id: number, data): Promise<ICompany> {
  const response = await api.put(`${address}/${id}`, data);
  return response.data;
}

export async function deleteCompany(id: number) {
  const response = await api.delete(`${address}/${id}`);
  return response.data;
}
