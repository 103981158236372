import styled from "styled-components";

export default styled.div`
  gap: 10px;
  width: 100%;

  .swiper {
    width: clamp(250px, 100%, 100%);
    height: 17.5rem;
    padding-bottom: 20px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-pagination-bullet-active {
    background-color: ${(props) => props.color ?? "black"};
  }
`;
