import { Center } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import SimpleSidebar from "../SimpleSidebar";

export function DashboardLayout() {
  return (
    <>
      <SimpleSidebar key={2}>
        <Center py="8" maxW="100%">
          <Outlet />
        </Center>
      </SimpleSidebar>
    </>
  );
}
