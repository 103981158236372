import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { useField } from "formik";

interface FieldInputProps extends InputProps {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  testID?: string;
}

export const FieldInput = ({
  label,
  name,
  isDisabled,
  isRequired,
  placeholder,
  testID,
  type,
  ...props
}: FieldInputProps) => {
  const [field, meta] = useField(name);

  const hasAnErrorAndHasBeenTouched = !!meta.error && meta.touched;

  return (
    <FormControl
      mt="1rem"
      isInvalid={hasAnErrorAndHasBeenTouched}
      isRequired={isRequired}
      isDisabled={isDisabled}
      placeholder={placeholder}
    >
      <Flex align="end">
        <FormLabel minW="11rem" ml="-5rem" textAlign="right" htmlFor={name} fontSize="1rem">
          {label}:
        </FormLabel>
        <Input
          {...props}
          {...field}
          id={name}
          type={type}
          border="none"
          bgColor="facebook.900"
          h="9"
          data-testid={testID}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </Flex>
    </FormControl>
  );
};
