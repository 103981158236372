import { createContext, useState } from "react";
import api, { removeToken } from "../services/api";
import { IUser } from "../interfaces/IUser";
import { toast } from "react-toastify";

interface Props {
  children?: React.ReactNode;
}

interface AuthContextData {
  token: string;
  userData: IUser;
  LogIn(email: string, password: string): Promise<any>;
  LogOut(): void;
  IsLogged(): boolean;
  IsAdmin(): boolean;
}

export interface LoginResponse {
  token: string;
  id: number;
  name: string;
  profile: string;
  companyId: number | null;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(GetSavedToken());
  const [userData, setUserData] = useState<IUser>(GetSavedUserData());

  function GetSavedUserData(): IUser {
    const savedData = localStorage.getItem("userData");
    try {
      if (savedData) {
        return JSON.parse(savedData);
      }
      return {} as IUser;
    } catch {
      return {} as IUser;
    }
  }

  function GetSavedToken(): string {
    const token = localStorage.getItem("token");
    if (token != null && token !== "") api.defaults.headers["Authorization"] = `Bearer ${token}`;
    return token || "";
  }

  function validateEmail(email) {
    return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  }

  async function LogIn(email: string, password: string) {
    const msg = toast.loading("Checando credenciais...");
    try {
      if (!email || !password) toast.warn("Insira um e-mail e senha");
      if (!validateEmail(email)) toast.warn("Insira um e-mail válido");
      const result = await api.post("/auth", { email, password });
      const data: LoginResponse = result.data;

      const user = {
        email: email,
        id: data.id,
        name: data.name,
        companyId: data.companyId,
        profile: data.profile,
      };

      setUserData(user);
      setToken(data.token);

      api.defaults.headers["Authorization"] = `Bearer ${data.token}`;
      localStorage.setItem("token", data.token);
      localStorage.setItem("userData", JSON.stringify(user));

      toast.update(msg, {
        render: "Sucesso",
        type: "success",
        isLoading: false,
      });
    } catch (error) {
      removeToken();
      toast.update(msg, {
        render: LoginErrorHandler(error),
        type: "error",
        isLoading: false,
      });
    }
  }

  const LoginErrorHandler = (error) => {
    if (error?.status === undefined || error?.status === 500) return "Sem resposta do servidor";
    if (error?.status === 401) return "E-mail ou senha incorretos";
    return "Ocorreu um erro";
  };

  async function LogOut() {
    localStorage.setItem("token", "");
    setToken("");
  }

  function IsLogged(): boolean {
    return token !== null && token !== "";
  }

  function IsAdmin(): boolean {
    return userData.profile === "ADMIN";
  }

  return (
    <AuthContext.Provider value={{ token, userData, LogIn, LogOut, IsLogged, IsAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};
