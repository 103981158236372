import { Button, Flex, IconButton, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useDeleteCompany, useGetCompanies } from "../../../services/hooks/useCompanies";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";
import { TwoButtonModal } from "../../components/Modal";
import { CustomTable } from "../../components/Table";

export function Companies() {
  const { data, isError, isFetching, refetch } = useGetCompanies();
  const deleteCompany = useDeleteCompany();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyToDelete, setCompanyToDelete] = useState(0);

  if (isFetching) return <LoadingPage message="Buscando companhias" />;
  if (isError) return <ErrorPage />;

  function onDeleteSuccess() {
    toast({ title: "Companhia deletada com sucesso", status: "success", position: "top-right" });
    onClose();
    refetch();
  }

  return (
    <>
      <PageTitle title="Companhias - Dashboard" />
      <TwoButtonModal
        title="Você tem certeza?"
        body={
          <Text>Deletar essa companhia pode gerar erros aos usuários que fazem parte dela.</Text>
        }
        isOpen={isOpen}
        onClose={onClose}
        closeText="Cancelar"
        secondaryText="Aceitar"
        secondaryAction={() =>
          deleteCompany.mutate(companyToDelete, { onSuccess: onDeleteSuccess })
        }
      />
      <Flex direction="column" minW="xl">
        <Flex>
          <Button
            as={Link}
            colorScheme={"telegram"}
            bgColor="telegram.700"
            color="white"
            to="/dashboard/companies/new"
          >
            Adicionar Companhia
          </Button>
        </Flex>
        <CustomTable
          columns={[
            { header: "ID", propKey: (obj) => obj.id },
            { header: "Nome", propKey: (obj) => obj.name },
            {
              header: "Ações",
              customComponent: function Actions(obj) {
                return (
                  <Flex gap="2">
                    <IconButton
                      as={Link}
                      aria-label={"Editar"}
                      icon={<FaEdit />}
                      colorScheme={"telegram"}
                      bgColor="telegram.700"
                      to={`/dashboard/companies/${obj.id}`}
                    />
                    <IconButton
                      aria-label={"Deletar"}
                      icon={<FaTrash />}
                      colorScheme={"red"}
                      bgColor="red.700"
                      onClick={() => {
                        setCompanyToDelete(obj.id);
                        onOpen();
                      }}
                    />
                  </Flex>
                );
              },
            },
          ]}
          data={data?.sort((a, b) => a.id - b.id) ?? []}
          getDataId={(obj) => obj.id}
          title="Lista de Companhias"
        ></CustomTable>
      </Flex>
    </>
  );
}
