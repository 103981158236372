import { Button, Flex, IconButton, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FaEdit, FaImage, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useDeleteProduct, useGetProducts } from "../../../services/hooks/useProducts";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";
import { TwoButtonModal } from "../../components/Modal";
import { CustomTable } from "../../components/Table";

export function Products() {
  const { data, isError, isFetching, refetch } = useGetProducts();
  const deleteProduct = useDeleteProduct();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productToDelete, setProductToDelete] = useState(0);

  if (isFetching) return <LoadingPage message="Buscando produtos" />;
  if (isError) return <ErrorPage />;

  function onDeleteSuccess() {
    toast({ title: "Produto deletado com sucesso", status: "success", position: "top-right" });
    onClose();
    refetch();
  }

  return (
    <Flex direction="column" width="100%">
      <PageTitle title="Produtos - Dashboard" />
      <TwoButtonModal
        title="Você tem certeza?"
        body={<Text>O produto será deletado e não haverá como desfazer essa decisão.</Text>}
        isOpen={isOpen}
        onClose={onClose}
        closeText="Cancelar"
        secondaryText="Aceitar"
        secondaryAction={() =>
          deleteProduct.mutate(productToDelete, { onSuccess: onDeleteSuccess })
        }
      />
      <Flex>
        <Button
          as={Link}
          colorScheme={"telegram"}
          bgColor="telegram.700"
          color="white"
          to={"/dashboard/products/new"}
        >
          Adicionar Produto
        </Button>
      </Flex>
      <CustomTable
        columns={[
          {
            header: "Imagens",
            customComponent: function Images(obj) {
              return (
                <Button
                  as={Link}
                  aria-label={"Editar"}
                  colorScheme={"whatsapp"}
                  bgColor="whatsapp.700"
                  to={`/dashboard/products/${obj.id}/images`}
                  gap="1"
                >
                  <FaImage /> {obj.images.length}
                </Button>
              );
            },
          },
          { header: "ID", propKey: (obj) => obj.id },
          { header: "Nome", propKey: (obj) => obj.name },
          {
            header: "Companhia",
            propKey: (obj) => obj.company?.name || "-",
          },
          { header: "Categoria", propKey: (obj) => obj.category?.name || "-" },
          {
            header: "Ações",
            customComponent: function Actions(obj) {
              return (
                <Flex gap="2">
                  <IconButton
                    as={Link}
                    aria-label={"Editar"}
                    icon={<FaEdit />}
                    colorScheme={"telegram"}
                    bgColor="telegram.700"
                    to={`/dashboard/products/${obj.id}`}
                  />
                  <IconButton
                    aria-label={"Deletar"}
                    icon={<FaTrash />}
                    colorScheme={"red"}
                    bgColor="red.700"
                    onClick={() => {
                      setProductToDelete(obj.id);
                      onOpen();
                    }}
                  />
                </Flex>
              );
            },
          },
        ]}
        data={data?.sort((a, b) => a.id - b.id) ?? []}
        getDataId={(obj) => obj.id}
        title="Lista de Produtos"
      ></CustomTable>
    </Flex>
  );
}
