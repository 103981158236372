let theme: any = {};

theme.colors = {
  white: "#FFFFFF",
  white_secondary: "#EFEFEF",
  blue: "#0082C9",
  blue600: "#0092D8",
  blue800: "#00A9EF",
  green: "#5C9C35",
};

export default theme;
