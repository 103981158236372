import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import { DashboardLayout } from "../dashboard/components/Layout";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import Home from "../pages/Home";
import { Catalog } from "../pages/Catalog/Catalog";
import Features from "../pages/Features";
import { ChakraProvider } from "@chakra-ui/react";
import Categories from "../dashboard/pages/Categories/Categories";
import { Users } from "../dashboard/pages/Users/Users";
import { Companies } from "../dashboard/pages/Companies/Companies";
import { Layout } from "../components/Layout";
import NotFound from "../pages/NotFound";
import { Products } from "../dashboard/pages/Products/Products";
import { UpdateCompany } from "../dashboard/pages/Companies/UpdateCompany";
import { CreateCompany } from "../dashboard/pages/Companies/CreateCompany";
import { CreateProduct } from "../dashboard/pages/Products/CreateProduct";
import { UpdateProduct } from "../dashboard/pages/Products/UpdateProduct";
import { UpdateUser } from "../dashboard/pages/Users/UpdateUser";
import { CreateUser } from "../dashboard/pages/Users/CreateUser";
import { UpdateCategory } from "../dashboard/pages/Categories/UpdateCategory";
import { CreateCategory } from "../dashboard/pages/Categories/CreateCategory";
import { Images } from "../dashboard/pages/Images/Images";
import ProductPage from "../pages/ProductPage/ProductPage";
import { CatalogProvider } from "../contexts/CatalogContext";
import { ExclusivePage } from "../pages/ExclusivePage/ExclusivePage";
import { Category } from "../pages/Category/Category";
import About from "../pages/About/About";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <ChakraProvider>
              <Layout />
            </ChakraProvider>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/categorias/:categoryId" element={<Category />} />
          <Route path="/produtos/:productId" element={<ProductPage />} />
          <Route path="/produtos" element={<Catalog />} />
          <Route path="/lancamentos" element={<Features />} />
          <Route path="/exclusivo" element={<ExclusivePage />} />
          <Route path="/sobre" element={<About />} />
        </Route>
        <Route
          element={
            <ChakraProvider>
              <DashboardLayout />
            </ChakraProvider>
          }
        >
          <Route path="/dashboard/" element={<Navigate to="/dashboard/categories" />} />

          <Route path="/dashboard/categories/new" element={<CreateCategory />} />
          <Route path="/dashboard/categories/:id" element={<UpdateCategory />} />
          <Route path="/dashboard/categories" element={<Categories />} />

          <Route path="/dashboard/users/new" element={<CreateUser />} />
          <Route path="/dashboard/users/:id" element={<UpdateUser />} />
          <Route path="/dashboard/users" element={<Users />} />

          <Route path="/dashboard/companies/new" element={<CreateCompany />} />
          <Route path="/dashboard/companies/:id" element={<UpdateCompany />} />
          <Route path="/dashboard/companies" element={<Companies />} />

          <Route path="/dashboard/products/new" element={<CreateProduct />} />
          <Route path="/dashboard/products/:id/images" element={<Images />} />
          <Route path="/dashboard/products/:id" element={<UpdateProduct />} />
          <Route path="/dashboard/products" element={<Products />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
