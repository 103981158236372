import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { ICompany } from "../../interfaces/ICompany";
import { RequestError } from "../api";
import {
  createCompany,
  deleteCompany,
  findCompany,
  listCompanies,
  updateCompany,
} from "../routes/companies";

export function useGetCompanies() {
  return useQuery<ICompany[], RequestError>("listProducts", () => listCompanies(), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useGetCompany(id: number) {
  return useQuery<ICompany, RequestError>(["findProduct", id], () => findCompany(id), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useCreateCompany() {
  return useMutation(createCompany);
}

export function useUpdateCompany(id: number) {
  return useMutation((data: any) => updateCompany(id, data));
}

export function useDeleteCompany() {
  return useMutation((id: number) => deleteCompany(id));
}
