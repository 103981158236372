import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useContext } from "react";
import { FaSignOutAlt, FaTools, FaUserAlt, FaSignInAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import theme from "../../styles/theme";

export default function UserMenu() {
  const navigate = useNavigate();
  const { IsLogged, LogOut, userData } = useContext(AuthContext);

  const menuItem = {
    active: { bgColor: "white" },
    hover: { bgColor: theme.colors.blue600 },
  };

  if (IsLogged()) return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          aria-label="Usuário"
          leftIcon={<FaUserAlt />}
          color="white"
          bgColor="transparent"
          _hover={{ bgColor: "white", color: theme.colors.blue }}
          _active={{ bgColor: "white", color: theme.colors.blue }}
        >
          Bem-vindo, {userData.name}
        </MenuButton>
        <MenuList zIndex={2} bgColor={theme.colors.blue}>
          {userData.profile === "ADMIN" && (
            <MenuItem
              _hover={menuItem.hover}
              _focus={menuItem.hover}
              color="white"
              icon={<FaTools />}
              onClick={() => navigate("/dashboard")}
            >
              Painel de Controle
            </MenuItem>
          )}
          {/* <MenuItem
            _hover={menuItem.hover}
            _focus={menuItem.hover}
            color="white"
            icon={<FaUserCircle />}
            onClick={() => navigate("/profile")}
          >
            Perfil
          </MenuItem> */}
          <MenuItem
            _hover={menuItem.hover}
            _focus={menuItem.hover}
            color="white"
            icon={<FaSignOutAlt />}
            as={Link}
            onClick={LogOut}
            to="/"
          >
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );

  return (
    <>
      <Button 
        aria-label="Usuário"
        leftIcon={<FaSignInAlt />}
        color="white"
        bgColor="transparent"
        _hover={{ bgColor: "white", color: theme.colors.blue }}
        _active={{ bgColor: "white", color: theme.colors.blue }}
        onClick={() => navigate("/login")}>
        Entrar
      </Button>
    </>
  )
}
