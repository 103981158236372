import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaImage, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { IProduct } from "../../../interfaces/IProduct";
import { useUploadImage, useDeleteImage, useGetProduct } from "../../../services/hooks/useProducts";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";
import { ChromePicker } from "react-color";
import api from "../../../services/api";
import { IImage } from "../../../interfaces/IImage";
import { request } from "https";

export function Images() {
  const toast = useToast();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const { data, isLoading, isError, refetch } = useGetProduct(Number(id));

  if (isLoading) return <LoadingPage message="Buscando imagens" />;
  if (isError || data === undefined) return <ErrorPage />;

  function onSuccess(text: string) {
    toast({ title: text, status: "success", position: "top-right" });
    setLoading(false);
    refetch();
  }
  function onError() {
    toast({ title: "Erro inesperado no envio", status: "error", position: "top-right" });
    setLoading(false);
    refetch();
  }
  function onLoading() {
    setLoading(true);
  }
  return (
    <Center flexDirection="column">
      {loading && <Spinner />}
      <ImagesForm
        product={data}
        onLoading={onLoading}
        onSuccess={onSuccess}
        onError={onError}
        refetch={refetch}
      />
    </Center>
  );
}

interface ImageFormProps {
  product: IProduct;
  onLoading: () => void;
  onSuccess: (text: string) => void;
  onError: () => void;
  refetch: () => void;
}

function ImagesForm({ product, onLoading, onSuccess, onError, refetch }: ImageFormProps) {
  const createImage = useUploadImage(product.id);
  const deleteImage = useDeleteImage();
  const [color, setColor] = useState("");
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorLoading, setColorLoading] = useState(0);

  async function attachColor(image: IImage) {
    setColorLoading(image.id);
    await api.patch(`/images/attach-color/${image.id}`, { color, url: image.url });
    setColorLoading(0);
    refetch();
  }

  return (
    <Flex direction="column" w="35rem">
      <PageTitle title="Adicionar Imagens - Dashboard" />
      <Heading fontSize="21" textAlign="center" mb="6">
        ADICIONAR IMAGENS
      </Heading>
      <Center mb="10">
        {product.images.length > 0 &&
          (isColorPickerOpen ? (
            <Flex direction="column" gap="3" justify="center" align="center">
              <ChromePicker color={color} onChange={(color) => setColor(color.hex)} />
              <Text fontSize="13" color="telegram.200">
                Clica na cor da imagem que você quer alterar
              </Text>
            </Flex>
          ) : (
            <Button
              onClick={() => setIsColorPickerOpen(true)}
              type="button"
              colorScheme="telegram"
              bgColor="telegram.900"
            >
              Alterar Cor
            </Button>
          ))}
      </Center>
      <Flex justify="center" direction="row" wrap="wrap" gap="2">
        {product.images
          .sort((a, b) => a.id - b.id)
          .map((image) => {
            return (
              <Box
                position="relative"
                key={image.id}
                bgColor="white"
                borderRadius="10"
                as={Center}
                minWidth="120"
                height="120"
              >
                <IconButton
                  aria-label="deleteImage"
                  icon={<FaTimes />}
                  colorScheme="red"
                  position="absolute"
                  top="0"
                  right="0"
                  size="8"
                  shadow="dark-lg"
                  onClick={() => {
                    deleteImage.mutate(image.id, {
                      onSuccess: () => onSuccess("Imagem deletada com sucesso"),
                      onError,
                    });
                  }}
                />
                <Flex
                  align="center"
                  justify="center"
                  position="absolute"
                  bottom="0"
                  left="0"
                  bgColor="white"
                  minW="6"
                  minH="6"
                  borderRadius="6"
                >
                  {image.id === colorLoading ? (
                    <Spinner
                      colorScheme="telegram"
                      color="telegram.500"
                      size="md"
                      position="absolute"
                      bottom="0"
                      left="0"
                    />
                  ) : (
                    <Button
                      size="xs"
                      aria-label="attachColor"
                      shadow="dark-lg"
                      variant="unstyled"
                      borderColor="white"
                      borderWidth="1px"
                      bgColor={image.color ?? "gray"}
                      _hover={{ bgColor: color }}
                      onClick={() => attachColor(image)}
                      _disabled={{ opacity: 1 }}
                      disabled={colorLoading !== 0 || color === ""}
                    />
                  )}
                </Flex>
                <Image p="1" borderRadius="10" maxWidth="120" maxHeight="120" src={image.url} />
              </Box>
            );
          })}
        <Button
          as="label"
          htmlFor="add-image"
          colorScheme="telegram"
          bgColor="telegram.900"
          minWidth="120"
          height="120"
          cursor="pointer"
        >
          <Center flexDirection="column">
            <FaImage size="30" />
            Adicionar
          </Center>
        </Button>
        <Input
          id="add-image"
          type="file"
          display="none"
          accept="image/*"
          width="100"
          onChange={(e) => {
            if (!e.target.files) {
              return;
            }
            onLoading();
            createImage.mutate(e.target.files[0], {
              onSuccess: () => onSuccess("Imagem adicionada com sucesso"),
              onError,
            });
          }}
        />
      </Flex>
      <Center mt="6">
        <Button
          as={Link}
          type="button"
          variant="ghost"
          _hover={{ color: "black", bgColor: "white" }}
          to="/dashboard/products"
        >
          Voltar
        </Button>
      </Center>
    </Flex>
  );
}
