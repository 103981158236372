import { Button, Center, Flex, Heading, useToast } from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { ICategory } from "../../../interfaces/ICategory";
import { useGetCategory, useUpdateCategory } from "../../../services/hooks/useCategories";
import { ErrorPage } from "../../components/Error";
import { FieldInput } from "../../components/Form/FieldInput";
import { LoadingPage } from "../../components/Loading";

export function UpdateCategory() {
  const { id } = useParams();
  const toast = useToast();
  const { data, isError, isLoading } = useGetCategory(Number(id));
  const navigate = useNavigate();
  const goBack = () => navigate("/dashboard/categories");
  function onSuccess() {
    toast({
      title: "Categoria atualizada com sucesso",
      status: "success",
      isClosable: true,
      position: "top-right",
    });
    goBack();
  }
  function onError() {
    toast({ title: "É preciso especificar um nome", status: "error", position: "top-right" });
  }

  if (isLoading) return <LoadingPage message="Buscando categorias" />;
  if (isError || data?.name === undefined) return <ErrorPage />;

  return <UpdateCategoryForm data={data} id={Number(id)} onSuccess={onSuccess} onError={onError} />;
}

interface UpdateCategoryFormProps {
  data: ICategory;
  id: number;
  onSuccess: () => void;
  onError: () => void;
}

export function UpdateCategoryForm({ data, id, onSuccess }: UpdateCategoryFormProps) {
  const { mutate } = useUpdateCategory(id);
  const formik = useFormik({
    initialValues: {
      name: data?.name,
    },
    onSubmit: (values, helpers) => {
      helpers.resetForm();
      mutate(values, { onSuccess });
    },
  });
  return (
    <Flex direction="column">
      <PageTitle title="Editar Categoria - Dashboard" />
      <Heading fontSize="21" textAlign="center" mb="6">
        EDITAR CATEGORIA
      </Heading>
      <FormikProvider value={formik}>
        <Form>
          <FieldInput label={"Nome"} name={"name"} type="text" />
        </Form>
        <Center mt="6" gap="2">
          <Button
            as={Link}
            type="button"
            variant="ghost"
            _hover={{ color: "black", bgColor: "white" }}
            to="/dashboard/categories"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme={"telegram"}
            bgColor={"telegram.700"}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Alterar
          </Button>
        </Center>
      </FormikProvider>
    </Flex>
  );
}
