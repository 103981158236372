import { Box, Image, Link, Text } from "@chakra-ui/react";
import { FaEnvelope, FaHome, FaPhone } from "react-icons/fa";
import { Link as ReachLink } from "react-router-dom";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
const backgroundFooter = require("../../../assets/Background.png");

export default function Footer() {
  const Logo = require("../../../assets/Logo.png");

  return (
    <Box
      backgroundImage={backgroundFooter}
      backgroundRepeat="none"
      backgroundSize="cover"
      color="white"
      width="100%"
    >
      <Box
        width="100%"
        maxWidth="1280px"
        margin="0 auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={2}
        padding={2}
      >
        <Image src={Logo} alt="" width="260px" />
        <Box
          width="100%"
          display="flex"
          flexDirection={["column", "row"]}
          alignItems={["left"]}
          padding={[10]}
          justifyContent="space-evenly"
          gap={5}
        >
          <Box>
            <Box display="flex" gap={2} role="group">
              <Text _groupHover={{ color: "#4de04d" }}>
                <FaPhone size="26px" />
              </Text>
              <Box>
                <Text>
                  (11) 3273-9127 <br />
                  (11) 3273-9128 <br />
                  (11) 3273-9130
                </Text>
              </Box>
            </Box>
            <Box display="flex" gap={2} role="group">
              <Text _groupHover={{ color: "#4de04d" }}>
                <FaEnvelope size="26px" />
              </Text>
              <Text>contato@winnerpromotion.com</Text>
            </Box>
          </Box>
          <Box>
            <Box display="flex" gap={2} role="group">
              <Text _groupHover={{ color: "#ff4099cf" }}>
                <AiFillInstagram size="28px" />
              </Text>
              <Link as={ReachLink} to="/">
                Instagram
              </Link>
            </Box>
            <Box display="flex" gap={2} role="group">
              <Text _groupHover={{ color: "#0077ff" }}>
                <AiFillFacebook size="28px" />
              </Text>
              <Link as={ReachLink} to="/">
                Facebook
              </Link>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Link as={ReachLink} to="/">
              Políticas do site
            </Link>
            <Link as={ReachLink} to="/">
              Garantia de produto
            </Link>
            <Link as={ReachLink} to="/">
              Suporte
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
