import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { ProductsSlider } from "../../components/ProductsSlider";
import { CatalogContext } from "../../contexts/CatalogContext";
import { IProduct } from "../../interfaces/IProduct";
import theme from "../../styles/theme";

export default function Home() {
  const navigate = useNavigate();
  const { products: catalogProducts } = useContext(CatalogContext);
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    setProducts(catalogProducts);
  }, [catalogProducts]);

  return (
    <>
      <PageTitle title="Winner Presentes" />
      <Center flexDirection="column">
        <Flex
          display={["none", "none", "none", "flex"]}
          align="center"
          wrap="wrap"
          flexDirection="row"
          pl="2"
          pr="2"
          pb="7"
          gap="3"
          width="100%"
          justify="center"
        >
          <Flex gap="3" wrap="wrap" direction="row">
            <Image
              borderRadius="1rem"
              maxW="220px"
              src={"https://i.imgur.com/LcpkhfS.jpg"}
              cursor="pointer"
              onClick={() => navigate("/produtos/153")}
            ></Image>
            <Image
              borderRadius="1rem"
              maxW="220px"
              src={"https://i.imgur.com/M8GHjZF.jpg"}
              cursor="pointer"
              onClick={() => navigate("/produtos/112")}
            ></Image>
            <Image
              borderRadius="1rem"
              maxW="220px"
              src={"https://i.imgur.com/qyJK8Gp.jpg"}
              cursor="pointer"
              onClick={() => navigate("/produtos/126")}
            ></Image>
            <Image
              borderRadius="1rem"
              maxW="220px"
              src={"https://i.imgur.com/uFJxoxH.jpg"}
              cursor="pointer"
              onClick={() => navigate("/produtos/112")}
            ></Image>
          </Flex>
        </Flex>
        <Box
          width="100%"
          paddingLeft="4"
          paddingTop="3"
          paddingBottom="3"
          color="white"
          fontWeight="bold"
          fontSize="xl"
          borderRadius="20"
          bgColor={theme.colors.blue}
        >
          POPULARES
        </Box>

        <ProductsSlider
          products={products
            .concat()
            .sort((a, b) => a.id - b.id)
            .slice(0, 20)}
          color={theme.colors.blue}
        />
        <Box
          width="100%"
          paddingLeft="4"
          paddingTop="3"
          paddingBottom="3"
          color="white"
          fontWeight="bold"
          fontSize="xl"
          borderRadius="20"
          bgColor={theme.colors.green}
        >
          NOVIDADES
        </Box>

        <ProductsSlider
          products={[...products].sort((a, b) => b.id - a.id).slice(0, 20)}
          color={theme.colors.green}
        />
      </Center>
    </>
  );
}
