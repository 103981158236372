import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CatalogContext } from "../../contexts/CatalogContext";
import theme from "../../styles/theme";
import Searchbar from "../Searchbar";
import UserMenu from "../UserMenu";
import Style from "./style";

export default function Navbar() {
  const { pathname } = useLocation();
  const { categories, hasCompanyPage, companyName } = useContext(CatalogContext);

  const menuItem = {
    active: { bgColor: "white" },
    hover: { bgColor: theme.colors.blue600 },
  };

  return (
    <Box as={Style}>
      <Center bgColor={theme.colors.blue} minH="3rem">
        <Container as={Flex} gap="2" maxW="container.lg" wrap="wrap">
          <Button
            bgColor="transparent"
            color="white"
            _hover={{
              color: theme.colors.blue,
              bgColor: theme.colors.white,
            }}
            as={Link}
            className={pathname === "/" ? "active" : ""}
            to="/"
          >
            HOME
          </Button>
          <Menu>
            <Button
              as={MenuButton}
              bgColor="transparent"
              color="white"
              _hover={{
                color: theme.colors.blue,
                bgColor: theme.colors.white,
              }}
              _active={{ bgColor: "white", color: theme.colors.blue }}
              className={
                pathname === "/produtos" ||
                pathname === "/exclusivo" ||
                pathname.includes("/categorias")
                  ? "active"
                  : ""
              }
            >
              PRODUTOS
            </Button>
            <MenuList zIndex={2} bgColor={theme.colors.blue}>
              {hasCompanyPage() && companyName !== "" && (
                <MenuItem
                  _hover={menuItem.hover}
                  _focus={menuItem.hover}
                  color="white"
                  fontWeight="bold"
                  as={Link}
                  to="/exclusivo"
                >
                  {companyName}
                </MenuItem>
              )}
              <MenuItem
                _hover={menuItem.hover}
                _focus={menuItem.hover}
                color="white"
                as={Link}
                to={`/produtos`}
                fontWeight="bold"
              >
                Ver todos
              </MenuItem>
              {categories.length > 0 &&
                categories.map((category, index) => {
                  return (
                    <MenuItem
                      key={index}
                      _hover={menuItem.hover}
                      _focus={menuItem.hover}
                      color="white"
                      as={Link}
                      to={`/categorias/${category.id}`}
                    >
                      {category.name}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>
          <Button
            bgColor="transparent"
            color="white"
            _hover={{
              color: theme.colors.blue,
              bgColor: theme.colors.white,
            }}
            as={Link}
            className={pathname === "/lancamentos" ? "active" : ""}
            to="/lancamentos"
          >
            LANÇAMENTOS
          </Button>
          <Spacer />
          <Searchbar />
          <Spacer />
          <UserMenu />
        </Container>
      </Center>
    </Box>
  );
}
