import { NavLink } from "react-router-dom";

export default function Logo() {
    const Logo = require('../../../assets/Logo.png');

    return (
        <NavLink to="/">
            <img src={Logo} alt="Winner Presentes Corporativos" width="600px" />
        </NavLink>
    )
}