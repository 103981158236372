import React from "react";
import { IProduct } from "../../interfaces/IProduct";
import Product from "../Product/Product";
import Div from "./style";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, A11y } from "swiper";

interface Props {
  products: IProduct[];
  color: string;
}

export const ProductsSlider: React.FC<Props> = ({ products, color }: Props) => {
  return (
    <Div color={color}>
      <Swiper
        slidesPerView={2}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          160: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesPerGroup: 3,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 4,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
            slidesPerGroup: 5,
          },
        }}
        scrollbar={{ draggable: true }}
        modules={[Pagination, A11y]}
      >
        {products.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <Product product={product} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Div>
  );
};
