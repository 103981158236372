import { ICategory } from "../../interfaces/ICategory";
import api from "../api";

const address = "/categories";

export async function findCategory(id: number): Promise<ICategory> {
  const response = await api.get(`${address}/${id}`);
  return response.data;
}

export async function listCategories(): Promise<ICategory[]> {
  const response = await api.get(address);
  return response.data;
}

export async function createCategory(data): Promise<ICategory> {
  const response = await api.post(address, data);
  return response.data;
}

export async function updateCategory(id: number, data): Promise<ICategory> {
  const response = await api.put(`${address}/${id}`, data);
  return response.data;
}

export async function deleteCategory(id: number) {
  const response = await api.delete(`${address}/${id}`);
  return response.data;
}
