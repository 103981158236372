import { Box, Button, Center, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IProduct } from "../../interfaces/IProduct";
import { convertId } from "../../services/id";

interface ProductProps {
  product: IProduct;
}

const fallback = require("../../assets/placeholder.png");

export default function Product({ product }: ProductProps) {
  const [activeImage, setActiveImage] = useState("");
  const [id, setId] = useState("00000");

  useEffect(() => {
    const converted = convertId(product.id);
    setId(converted);
  }, [product.id]);

  useEffect(() => {
    if (product.images.sort((a, b) => a.id - b.id)[0] !== undefined) {
      setActiveImage(product.images.concat().sort((a, b) => a.id - b.id)[0].url);
    } else {
      setActiveImage(fallback);
    }
  }, [product]);

  return (
    <Box
      p="2"
      minW={["6rem", "10rem"]}
      height="15rem"
      display="flex"
      flexDirection={"column"}
      borderRadius="lg"
      borderWidth="1px"
      borderColor="transparent"
      shadow={"base"}
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      transition={"0.2s"}
      cursor="pointer"
      _hover={{
        boxShadow: "md",
        border: "solid 1px #00000030",
      }}
      as={Link}
      to={`/produtos/${product.id}`}
    >
      <Center width="120px" height="120px" mb="3">
        <Image
          onPointerEnter={() => {
            if (product.images[1] !== undefined)
              setActiveImage(product.images.concat().sort((a, b) => a.id - b.id)[1].url);
          }}
          onPointerLeave={() => {
            if (product.images[0] !== undefined)
              setActiveImage(product.images.concat().sort((a, b) => a.id - b.id)[0].url);
          }}
          src={activeImage}
          alt={product.name}
        />
      </Center>
      <Text mt="1" fontSize="md" fontWeight={"bold"} textAlign="center">
        {id}
      </Text>
      <Text fontSize="md" lineHeight="92%" textAlign="center">
        {product.name}
      </Text>
      <Flex mt="2" gap="1" maxWidth="80%" wrap="wrap" height="22px" justify="center" align="center">
        {product.images.length > 0 &&
          product.images
            .filter((image) => image.color !== undefined && image.color !== null)
            .slice(0, 6)
            .map((image, index) => {
              return (
                <Box
                  shadow="md"
                  borderWidth="1px"
                  borderColor="blackAlpha.200"
                  width="3"
                  height="3"
                  key={index}
                  bgColor={image.color!}
                  borderRadius="7"
                  opacity="0.9"
                  _hover={{ opacity: 1 }}
                  onMouseEnter={() => {
                    setActiveImage(image.url);
                  }}
                  onMouseLeave={() => {
                    setActiveImage(product.images.concat().sort((a, b) => a.id - b.id)[0].url);
                  }}
                />
              );
            })}
      </Flex>
    </Box>
  );
}
