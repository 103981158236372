import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface TwoButtonModalProps {
  title: string;
  body: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  closeText: string;
  secondaryText: string;
  secondaryAction: () => void;
}

export function TwoButtonModal({
  title,
  body,
  isOpen,
  onClose,
  closeText,
  secondaryText,
  secondaryAction,
}: TwoButtonModalProps) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button type="submit" colorScheme="blue" mr={3} onClick={secondaryAction}>
            {secondaryText}
          </Button>
          <Button type="button" variant="ghost" onClick={onClose}>
            {closeText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
