import { Box, Button, Text } from "@chakra-ui/react";
import {
  FaAccessibleIcon,
  FaAddressCard,
  FaAlignCenter,
  FaCertificate,
  FaPhoneAlt,
  FaPhoneSquare,
  FaPhoneSquareAlt,
  FaSignInAlt,
  FaUsers,
  FaWhatsapp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
const Background = require("../../assets/Background.png");

export default function Header() {
  const navigate = useNavigate();
  return (
    <Box
      backgroundImage={Background}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={["column", "row"]}
      gap={2}
      padding={[4, 0]}
    >
      <Logo />
      <Box flexWrap="wrap" cursor="pointer" padding="1rem 2rem 0 0">
        <Button
          color="white"
          variant="ghost"
          fontWeight="bold"
          fontSize="19px"
          opacity={0.92}
          height="2"
          leftIcon={<FaUsers />}
          _hover={{
            opacity: 1,
          }}
          _active={{}}
          onClick={() => navigate("/sobre")}
        >
          SOBRE NÓS
        </Button>
        <br />
        <Button
          color="white"
          variant="ghost"
          fontWeight="bold"
          fontSize="19px"
          opacity={0.92}
          height="2"
          leftIcon={<FaPhoneSquareAlt />}
          _hover={{
            opacity: 1,
          }}
          _active={{}}
          onClick={() => navigate("/sobre")}
        >
          CONTATO
        </Button>
      </Box>
    </Box>
  );
}
