import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { IUser } from "../../../interfaces/IUser";
import { useGetUser, useUpdateUser } from "../../../services/hooks/useUsers";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";

const Profile = {
  USER: "USER",
  ADMIN: "ADMIN",
};

export function UpdateUser() {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();

  // const {
  //   data: companies,
  //   isFetching: isCompaniesFetching,
  //   isError: isCompaniesError,
  // } = useGetCompanies();

  const { data: user, isLoading: isUserLoading, isError: isUserError } = useGetUser(Number(id));

  if (isUserLoading) return <LoadingPage message="Buscando opções do usuário" />;
  if (isUserError || user === undefined) return <ErrorPage />;

  const goBack = () => navigate("/dashboard/users");

  function onSuccess() {
    toast({ title: "Usuário alterado com sucesso", status: "success", position: "top-right" });
    goBack();
  }
  function onError() {
    toast({ title: "Ocorreu um erro", status: "error", position: "top-right" });
  }

  return <UpdateUserForm user={user} onSuccess={onSuccess} onError={onError} />;
}

interface CreateUserFormProps {
  user: IUser;
  onSuccess: () => void;
  onError: () => void;
}

function UpdateUserForm({ user, onSuccess }: CreateUserFormProps) {
  const { mutate } = useUpdateUser(user.id);

  const formik = useFormik({
    initialValues: {
      // name: user.name,
      // email: user.email,
      // companyId: user.companyId,
      profile: user.profile,
    },
    onSubmit: (values, helpers) => {
      helpers.resetForm();
      mutate(values, { onSuccess });
    },
  });

  return (
    <Flex direction="column">
      <PageTitle title="Editar Usuário - Dashboard" />
      <Heading fontSize="21" textAlign="center" mb="6">
        ATUALIZAR USUÁRIO
      </Heading>
      <FormikProvider value={formik}>
        <Form>
          {/* <FieldInput label={"Nome"} name={"name"} type="text" />
          <FieldInput label={"E-mail"} name={"email"} type="text" />
          <FormControl mt="1rem">
            <Flex align="end" justify="center">
              <FormLabel
                minW="11rem"
                ml="-5rem"
                textAlign={"right"}
                htmlFor="company"
                fontSize="1rem"
              >
                Companhia:
              </FormLabel>
              <Select
                colorScheme={"telegram"}
                border="none"
                h="9"
                bgColor="facebook.900"
                color="white"
                name="companyId"
              >
                <option style={{ color: "white", backgroundColor: "#0d1b33" }} value={0}>
                  Nenhuma
                </option>
                {companies.map((company) => {
                  return (
                    <option
                      style={{ color: "white", backgroundColor: "#0d1b33" }}
                      key={company.id}
                      value={company.id}
                    >
                      {company.name}
                    </option>
                  );
                })}
              </Select>
            </Flex>
          </FormControl> */}
          <FormControl mt="1rem">
            <Flex align="end" justify="center">
              <FormLabel
                minW="11rem"
                ml="-5rem"
                textAlign={"right"}
                htmlFor="profile"
                fontSize="1rem"
              >
                Perfil:
              </FormLabel>
              <Field
                as={Select}
                name="profile"
                colorScheme={"telegram"}
                border="none"
                h="9"
                bgColor="facebook.900"
                color="white"
              >
                <option
                  style={{ color: "white", backgroundColor: "#0d1b33" }}
                  label={"Usuário"}
                  value={Profile.USER}
                />
                <option
                  style={{ color: "white", backgroundColor: "#0d1b33" }}
                  label={"Administrador"}
                  value={Profile.ADMIN}
                />
              </Field>
            </Flex>
          </FormControl>
        </Form>
        <Center mt="6" gap="2">
          <Button
            as={Link}
            type="button"
            variant="ghost"
            _hover={{ color: "black", bgColor: "white" }}
            to="/dashboard/users"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme={"telegram"}
            bgColor={"telegram.700"}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Alterar
          </Button>
        </Center>
      </FormikProvider>
    </Flex>
  );
}
