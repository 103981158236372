import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { ICategory } from "../../../interfaces/ICategory";
import { ICompany } from "../../../interfaces/ICompany";
import { useGetCategories } from "../../../services/hooks/useCategories";
import { useGetCompanies } from "../../../services/hooks/useCompanies";
import { useCreateProduct } from "../../../services/hooks/useProducts";
import { ErrorPage } from "../../components/Error";
import { FieldInput } from "../../components/Form/FieldInput";
import { FieldTextarea } from "../../components/Form/FieldTextarea";
import { LoadingPage } from "../../components/Loading";

export function CreateProduct() {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    isError: isCompaniesError,
  } = useGetCompanies();

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useGetCategories();

  if (isCompaniesLoading || isCategoriesLoading)
    return <LoadingPage message="Buscando opções de produto" />;
  if (isCompaniesError || isCategoriesError || companies === undefined || categories === undefined)
    return <ErrorPage />;

  const goBack = () => navigate("/dashboard/products");

  function onSuccess() {
    toast({ title: "Produto criado com sucesso", status: "success", position: "top-right" });
    goBack();
  }
  function onError() {
    toast({ title: "Nome e descrição são obrigatórios", status: "error", position: "top-right" });
  }
  return (
    <CreateProductForm
      categories={categories}
      companies={companies}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
}

interface CreateProductFormProps {
  categories: ICategory[];
  companies: ICompany[];
  onSuccess: () => void;
  onError: () => void;
}

function CreateProductForm({ categories, companies, onSuccess, onError }: CreateProductFormProps) {
  const { mutate } = useCreateProduct();
  const formik = useFormik({
    initialValues: {
      categoryId: 0,
      companyId: 0,
    },
    onSubmit: (values, helpers) => {
      helpers.resetForm();
      mutate(values, { onSuccess, onError });
    },
  });
  return (
    <Flex direction="column" w="25rem">
      <PageTitle title="Criar Produto - Dashboard" />
      <Heading fontSize="21" textAlign="center" mb="6">
        ADICIONAR PRODUTO
      </Heading>
      <FormikProvider value={formik}>
        <Form>
          <FieldInput label={"Nome"} name={"name"} type="text" />
          <FieldTextarea label={"Descrição"} name={"description"} type="text" />
          <Flex>
            <FieldInput zIndex={2} label={"Comprimento"} name={"width"} type="number" />
            <FieldInput label={"Peso"} name={"weight"} type="number" />
          </Flex>
          <Flex>
            <FieldInput zIndex={100} label={"Altura"} name={"height"} type="number" />
            <FieldInput label={"Espessura"} name={"thickness"} type="number" />
          </Flex>
          <FormControl mt="1rem">
            <Flex align="end" justify="center">
              <FormLabel
                minW="11rem"
                ml="-5rem"
                textAlign={"right"}
                htmlFor="company"
                fontSize="1rem"
              >
                Companhia:
              </FormLabel>
              <Field
                as={Select}
                colorScheme={"telegram"}
                border="none"
                h="9"
                bgColor="facebook.900"
                color="white"
                name="companyId"
              >
                <option style={{ color: "white", backgroundColor: "#0d1b33" }} value={0}>
                  Nenhuma
                </option>
                {companies.map((company) => {
                  return (
                    <option
                      style={{ color: "white", backgroundColor: "#0d1b33" }}
                      key={company.id}
                      value={company.id}
                    >
                      {company.name}
                    </option>
                  );
                })}
              </Field>
            </Flex>
          </FormControl>
          <FormControl mt="1rem">
            <Flex align="end" justify="center">
              <FormLabel
                minW="11rem"
                ml="-5rem"
                textAlign={"right"}
                htmlFor="category"
                fontSize="1rem"
              >
                Categoria:
              </FormLabel>
              <Field
                as={Select}
                colorScheme={"telegram"}
                border="none"
                h="9"
                bgColor="facebook.900"
                color="white"
                name="categoryId"
              >
                <option style={{ color: "white", backgroundColor: "#0d1b33" }} value={0}>
                  Nenhuma
                </option>
                {categories.map((category) => {
                  return (
                    <option
                      style={{ color: "white", backgroundColor: "#0d1b33" }}
                      key={category.id}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  );
                })}
              </Field>
            </Flex>
          </FormControl>
        </Form>
        <Center mt="6" gap="2">
          <Button
            as={Link}
            type="button"
            variant="ghost"
            _hover={{ color: "black", bgColor: "white" }}
            to="/dashboard/products"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme={"telegram"}
            bgColor={"telegram.700"}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Criar
          </Button>
        </Center>
      </FormikProvider>
    </Flex>
  );
}
