import { Button, Center, Flex, Heading, useToast } from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useCreateCategory } from "../../../services/hooks/useCategories";
import { FieldInput } from "../../components/Form/FieldInput";

export function CreateCategory() {
  const { mutate } = useCreateCategory();
  const navigate = useNavigate();
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, helpers) => {
      helpers.resetForm();
      mutate(values, { onSuccess, onError });
    },
  });
  const goBack = () => navigate("/dashboard/categories");
  function onSuccess() {
    toast({ title: "Categoria criada com sucesso", status: "success", position: "top-right" });
    goBack();
  }
  function onError() {
    toast({ title: "É preciso especificar um nome", status: "error", position: "top-right" });
  }
  return (
    <>
      <PageTitle title="Criar Categoria - Dashboard" />
      <Flex direction="column">
        <Heading fontSize="21" textAlign="center" mb="6">
          ADICIONAR CATEGORIA
        </Heading>
        <FormikProvider value={formik}>
          <Form>
            <FieldInput label={"Nome"} name={"name"} type="text" />
          </Form>
          <Center mt="6" gap="2">
            <Button
              as={Link}
              type="button"
              variant="ghost"
              _hover={{ color: "black", bgColor: "white" }}
              to="/dashboard/categories"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme={"telegram"}
              bgColor={"telegram.700"}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Criar
            </Button>
          </Center>
        </FormikProvider>
      </Flex>
    </>
  );
}
