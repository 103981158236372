export function convertId(val): string {
  if (getChar(val, 0) === String(val)) {
    return `0000${val}`;
  }
  if (getChar(val, 0) + getChar(val, 1) === String(val)) {
    return `000${val}`;
  }
  if (getChar(val, 0) + getChar(val, 1) + getChar(val, 2) === String(val)) {
    return `00${val}`;
  }
  if (getChar(val, 0) + getChar(val, 1) + getChar(val, 2) + getChar(val, 3) === String(val)) {
    return `0${val}`;
  }
  return val;
}

const getChar = (val: number, num: number) => {
  return String(Math.abs(val)).charAt(num);
};

