import { Box, Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "../Header";
import Navbar from "../Navbar/Navbar";
import { CatalogProvider } from "../../contexts/CatalogContext";
import AnnouncementSlider from "../AnnouncementSlider";

export function Layout() {
  return (
    <CatalogProvider>
      <Header />
      <AnnouncementSlider />
      <Navbar />
      <Box w="100%">
        <Container bgColor="white" maxW="container.lg" py={8} minH="container.sm">
          <Outlet />
        </Container>
      </Box>
      <Footer />
    </CatalogProvider>
  );
}
