import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { IUser } from "../../interfaces/IUser";
import { RequestError } from "../api";
import { createUser, findUser, listUsers, updateUser } from "../routes/users";

export function useGetUsers() {
  return useQuery<IUser[], RequestError>("listUsers", () => listUsers(), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useGetUser(id: number) {
  return useQuery<IUser, RequestError>(["findUser", Number(id)], () => findUser(Number(id)), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useCreateUser() {
  return useMutation(createUser);
}

export function useUpdateUser(id: number) {
  return useMutation((data: any) => updateUser(id, data));
}
