import { Center, Flex, IconButton, SimpleGrid, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import PageTitle from "../../components/PageTitle/PageTitle";
import Product from "../../components/Product/Product";
import { CatalogContext } from "../../contexts/CatalogContext";
import { IProduct } from "../../interfaces/IProduct";

export function Catalog() {
  const { products: data } = useContext(CatalogContext);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);
  const maxItemsPerPage = 20;

  useEffect(() => {
    function filterData() {
      return data.filter((product) => product.companyId === 0 || product.companyId === null);
    }
    setProducts(filterData);
  }, [data]);

  function totalPages() {
    return products.length / 20;
  }

  return (
    <>
      <PageTitle title="Catálogo" />

      <Center flexDirection="column">
        <Flex ml={["4", "15"]} mb="8" w="100%">
          <Text
            cursor="default"
            pb="2"
            pt="2"
            pl="4"
            pr="4"
            bgColor="telegram.600"
            color="white"
            borderRadius="16"
            fontWeight="bold"
            fontSize={["12", "13", "14"]}
          >
            Todos os Produtos
          </Text>
        </Flex>
        <SimpleGrid columns={[2, 3, 4, 5]} gap="5" maxW="100%">
          <CatalogPage maxItemsPerPage={maxItemsPerPage}   data={products} currentPage={page} />
        </SimpleGrid>

        <Flex mt="5" fontWeight="bold" gap="5" align="center">
          <IconButton
            disabled={page <= 1}
            aria-label="productsRight"
            size="sm"
            variant="ghost"
            icon={<FaCaretLeft size="24" />}
            onClick={() => setPage(page - 1)}
          ></IconButton>
          <Text>Página {page}</Text>
          <IconButton
            disabled={page >= totalPages()}
            aria-label="productsRight"
            size="sm"
            variant="ghost"
            icon={<FaCaretRight size="24" />}
            onClick={() => setPage(page + 1)}
          ></IconButton>
        </Flex>
      </Center>
    </>
  );
}

interface CatalogPageProps {
  data: IProduct[];
  currentPage: number;
  maxItemsPerPage: number;
}

function CatalogPage({ data, currentPage, maxItemsPerPage }: CatalogPageProps) {
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    if (data === undefined) return;
    const listOfProductsPerPage = data.filter(
      (_p, index) => {
        return index >= (maxItemsPerPage * currentPage - maxItemsPerPage) && index <= currentPage * maxItemsPerPage;
      }
    );
    setProducts(listOfProductsPerPage);
  }, [data, currentPage, maxItemsPerPage]);

  return (
    <>
      {products.map((product) => <Product key={product.id} product={product} />)}
    </>
  );
}
