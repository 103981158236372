import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { IProduct } from "../../interfaces/IProduct";
import { RequestError } from "../api";
import {
  createProduct,
  deleteImage,
  deleteProduct,
  findProduct,
  listProducts,
  updateProduct,
  uploadImage,
} from "../routes/products";

export function useGetProducts() {
  return useQuery<IProduct[], RequestError>("listProducts", () => listProducts(), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useGetProduct(id: number) {
  return useQuery<IProduct, RequestError>(["findProduct", id], () => findProduct(id), {
    onError: () => toast.error("Erro no servidor"),
  });
}

export function useCreateProduct() {
  return useMutation(createProduct);
}

export function useUpdateProduct(id: number) {
  return useMutation((data: any) => updateProduct(id, data));
}

export function useDeleteProduct() {
  return useMutation((id: number) => deleteProduct(id));
}

export function useUploadImage(productId: number) {
  return useMutation((data: any) => uploadImage(productId, data));
}

export function useDeleteImage() {
  return useMutation((id: number) => deleteImage(id));
}
