import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CatalogContext } from "../../contexts/CatalogContext";
import { convertId } from "../../services/id";
import theme from "../../styles/theme";

const fallback = require("../../assets/placeholder.png");

export default function Searchbar() {
  const { products } = useContext(CatalogContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <Popover
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        autoFocus={false}
      >
        <PopoverTrigger>
          <InputGroup maxW="3xs" _focusWithin={{ bgColor: "#ffffff20" }}>
            <InputLeftElement
              color="white"
              pointerEvents="none"
              _peerFocus={{ color: theme.colors.blue }}
              children={<FaSearch />}
            />
            <Input
              color="white"
              placeholder="O que busca?"
              _placeholder={{ color: "white" }}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                if (e.target.value !== "") onOpen();
                else onClose();
              }}
            />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody
            overflowY="scroll"
            overflowX="hidden"
            textOverflow="ellipsis"
            wordBreak={"break-all"}
            maxH="200px"
            w="318px"
            p="0"
            m="0"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bbccff",
                borderRadius: "24px",
              },
            }}
          >
            {products
              .filter(
                (product) =>
                  product.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                  convertId(product.id).includes(searchInput)
              )
              .map((product, index) => {
                return (
                  <Button
                    textOverflow="ellipsis"
                    wordBreak={"break-all"}
                    width="100%"
                    height="80px"
                    justifyContent="left"
                    key={index}
                    p="2"
                    onClick={() => {
                      onClose();
                      navigate(`/produtos/${product.id}`);
                    }}
                  >
                    <Image
                      width="40px"
                      height="40px"
                      src={
                        product?.images && !!product?.images.length
                          ? product?.images[0].url
                          : fallback
                      }
                      alt={product.name}
                    />
                    <Box
                      overflow="hidden"
                      textOverflow="ellipsis"
                      wordBreak={"break-all"}
                      ml="4"
                      w="100%"
                    >
                      <Text fontWeight="bold" width="100%">
                        {convertId(product.id)}
                      </Text>
                      <Text textOverflow="ellipsis" wordBreak={"break-all"} width="100%">
                        {product.name}
                      </Text>
                    </Box>
                  </Button>
                );
              })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
