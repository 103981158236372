import { Button, Flex, IconButton, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useDeleteCategory, useGetCategories } from "../../../services/hooks/useCategories";
import { ErrorPage } from "../../components/Error";
import { LoadingPage } from "../../components/Loading";
import { TwoButtonModal } from "../../components/Modal";
import { CustomTable } from "../../components/Table";

export default function Categories() {
  const { data, isError, isFetching, refetch } = useGetCategories();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteCategory = useDeleteCategory();

  const [categoryToDelete, setCategoryToDelete] = useState(0);

  const toast = useToast();

  if (isFetching) return <LoadingPage message="Buscando categorias" />;
  if (isError) return <ErrorPage />;

  function onDeleteSuccess() {
    toast({ title: "Categoria deletada com sucesso", status: "success", position: "top-right" });
    onClose();
    refetch();
  }

  return (
    <Flex direction="column" minW="xl">
      <TwoButtonModal
        title="Você tem certeza?"
        body={
          <Text>Deletar essa categoria pode gerar erros aos produtos que fazem parte dela.</Text>
        }
        isOpen={isOpen}
        onClose={onClose}
        closeText="Cancelar"
        secondaryText="Aceitar"
        secondaryAction={() =>
          deleteCategory.mutate(categoryToDelete, { onSuccess: onDeleteSuccess })
        }
      />
      <Flex>
        <Button
          as={Link}
          colorScheme={"telegram"}
          bgColor="telegram.700"
          color="white"
          to="/dashboard/categories/new"
        >
          Adicionar Categoria
        </Button>
      </Flex>
      <CustomTable
        columns={[
          { header: "ID", propKey: (obj) => obj.id },
          { header: "Nome", propKey: (obj) => obj.name },
          {
            header: "Ações",
            customComponent: function Actions(obj) {
              return (
                <Flex gap="2">
                  <PageTitle title="Categorias - Dashboard" />
                  <IconButton
                    as={Link}
                    aria-label={"Editar"}
                    icon={<FaEdit />}
                    colorScheme={"telegram"}
                    bgColor="telegram.700"
                    to={`/dashboard/categories/${obj.id}`}
                  />
                  <IconButton
                    aria-label={"Deletar"}
                    icon={<FaTrash />}
                    colorScheme={"red"}
                    bgColor="red.700"
                    onClick={() => {
                      setCategoryToDelete(obj.id);
                      onOpen();
                    }}
                  />
                </Flex>
              );
            },
          },
        ]}
        data={data?.sort((a, b) => a.id - b.id) ?? []}
        getDataId={(obj) => obj.id}
        title="Lista de Categorias"
      ></CustomTable>
    </Flex>
  );
}
